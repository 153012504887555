import imtn_logo from "./images/imtn_logo.png";
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={imtn_logo} className="App-logo" alt="logo" />
        <p>
          Imation Labs - Mi miamsi
          IMTN
        </p>
        <a
          className="App-link"
          href="https://www.exploit-db.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Piranhas are the best
        </a>
      </header>
    </div>
  );
}

export default App;
